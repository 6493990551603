@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100%;
}
